class Init {
	constructor() {
		const offcanvas = new Offcanvas();
		const accordions = new Accordion();
		const slider = new Slider();
		const headlines = new Headlines();
		const video = new Video();
	}
}

window.addEventListener('load', function () {
	new Init();
})
