class Slider {
	constructor() {
		function is_touch_enabled() {
			return ( 'ontouchstart' in window ) ||
				   ( navigator.maxTouchPoints > 0 ) ||
				   ( navigator.msMaxTouchPoints > 0 );
		}
		const touchDevice = is_touch_enabled();

		let slider = document.getElementsByClassName('slider-container');
		for(let x = 0; slider.length > x; x++) {
			let thumbnails = slider[x].getElementsByClassName('slider-thumbnail');
			let thumbnailsContainer = slider[x].getElementsByClassName('slider-thumbnails');
			let activeImages = slider[x].getElementsByClassName('active');
			// Add horizontal scroll support for Desktop
			if(!touchDevice && thumbnailsContainer[0]) {
				thumbnailsContainer[0].addEventListener("wheel", (evt) => {
					evt.preventDefault();
					thumbnailsContainer[0].scrollLeft += evt.deltaY;
				});
			}

			for (var i=0; i < thumbnails.length; i++){
				thumbnails[i].addEventListener('click', function(e){
					e.preventDefault()
					if (activeImages.length > 0){
					activeImages[0].classList.remove('active')
					}
					this.classList.add('active')
					slider[x].getElementsByClassName('featuredImage')[0].src = this.href
				})
			}
		}
	}
}
