class Headlines {
	constructor() {
		const headlines = document.getElementsByClassName('dynamic-headline');
		for (var i=0; i < headlines.length; ++i) {
			this.markWords(headlines[i]);
		}

		function boldSecondWord(elem) {

		}
	}

	markWords(elem) {
		elem.innerHTML = elem.textContent.replace(/[\wäöüÄÖÜ]+ ([\wäöüÄÖÜ]+)/, function(s, c) {
			return s.replace(c, '<span>'+c+'</span>');
		});
	}
}
