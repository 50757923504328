class Video {
	constructor() {
		const video = document.getElementById('heroVideo');
		const playButton = document.getElementsByClassName('play-button')[0];
		if(video) {
			playButton.addEventListener('click', () => {
				playButton.classList.toggle('playing');
				if(video.paused) {
					video.play();
					playButton.remove();
				}
			});
		}
	}
}
