class Offcanvas {
	constructor() {
		this.icon = document.getElementById('offcanvas-button');
		this.offcanvas = document.getElementById('offcanvas');
		this.overlay = document.getElementById('offcanvas-overlay');
		let i = 0;
		this.subMenus = offcanvas.getElementsByTagName('ul');
		for(i = 0; i < this.subMenus.length; i++) {
			let subMenu = this.subMenus[i];
			const subMenuPoint = subMenu.parentNode;
			const text = subMenuPoint.getElementsByTagName('a')[0].text;
			subMenuPoint.getElementsByTagName('a')[0].remove();

			const span = document.createElement("span");
			span.innerHTML = text;
			const icon = document.createElement("span");
			icon.classList.add('icon','icon-arrow-down');

			subMenuPoint.insertBefore(icon, subMenuPoint.firstChild);
			subMenuPoint.insertBefore(span, subMenuPoint.firstChild);

			subMenuPoint.classList.add('has-children');
			subMenuPoint.addEventListener("click", (e) => {
				e.stopPropagation();

				// Close Popup Dropdown ########################################

					let j = 0;
					for(j = 0; j < this.subMenus.length; j++) {
						const menu = this.subMenus[j];
						if(menu !== subMenu) {
							menu.classList.remove('active');
							menu.parentNode.classList.remove('active');
							menu.setAttribute('style',`height:0`);
							menu.style.height = 0;
						}
					}

				// ##############################################################
				subMenu.classList.toggle('active');
				subMenuPoint.classList.toggle('active');
				if(subMenuPoint.classList.contains('active')) {
					subMenu.setAttribute('style',`height:${subMenu.scrollHeight}px`);
					subMenu.style.height = subMenu.scrollHeight;
				} else {
					subMenu.setAttribute('style',`height:0`);
					subMenu.style.height = 0;
				}
			})
		}

		this.icon.addEventListener("click", ()=>{
			if(this.icon.classList.contains('active')) {
				this.deactivate();
			} else {
				this.activate();
			}
		});

		window.addEventListener("resize", ()=>{
			this.deactivate();
			let j = 0;
			for(j = 0; j < this.subMenus.length; j++) {
				const menu = this.subMenus[j];
				menu.classList.remove('active');
				menu.parentNode.classList.remove('active');
				menu.setAttribute('style',`height:0`);
				menu.style.height = 0;
			}
		});
	}

	deactivate() {
		this.icon.classList.remove('active');
		this.offcanvas.classList.remove('active');
		this.overlay.classList.remove('active');
		let i = 0;
		for(i = 0; i < this.subMenus.length; i++) {
			this.subMenus[i].classList.remove('active');
			this.subMenus[i].parentNode.classList.remove('active');
		}
	}

	activate() {
		this.overlay.classList.add('active');
		this.icon.classList.add('active');
		this.offcanvas.classList.add('active');
	}
}
