class Accordion {
	constructor() {
		const accordions = document.getElementsByClassName('accordion');
		let i = 0;
		if(accordions.length > 0) {
			for(i; i < accordions.length; i++) {
				const accordion = accordions[i];
				const header = accordion.getElementsByTagName('header')[0];
				header.addEventListener('click', ()=> {
					if(accordion.classList.contains('active')) {
						this.close(accordion);
					} else {
						this.open(accordion);
					}
				});
			}
		}
	}
	open(accordion) {
		accordion.classList.add('active');
		const content = accordion.getElementsByClassName('content')[0];
		content.setAttribute('style', `height:${content.scrollHeight}px`);
		content.style.height = content.scrollHeight;
	}

	close(accordion) {
		accordion.classList.remove('active');
		const content = accordion.getElementsByClassName('content')[0];
		content.setAttribute('style', `height:0px`);
		content.style.height = 0;
	}
}
